import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/flomesh.io/flomesh.io/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "查看-codebase-列表"
    }}>{`查看 codebase 列表`}</h3>
    <p><inlineCode parentName="p">{`GET /api/v1/repo`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl http://localhost:6060/api/v1/repo
/tutorial/01-hello
/tutorial/02-routing
/tutorial/03-connection-pool
/tutorial/04-load-balancing
/tutorial/05-logging
/tutorial/06-path-rewriting
/tutorial/07-jwt
/tutorial/08-plugins
/tutorial/09-configuration
/tutorial/10-ban
/tutorial/11-throttle
/tutorial/12-body-transform
`}</code></pre>
    <h3 {...{
      "id": "创建-codebase"
    }}>{`创建 codebase`}</h3>
    <p><inlineCode parentName="p">{`POST /api/v1/repo/[CODEBASE]`}</inlineCode></p>
    <p>{`创建 codebase 可以从零创建，也可以从其他 codebase `}<a parentName="p" {...{
        "href": "/operating/repo/0-intro#%E4%BB%A3%E7%A0%81%E5%BA%93%E7%BB%A7%E6%89%BF"
      }}>{`衍生`}</a>{`而来。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl -X POST http://localhost:6060/api/v1/repo/hello
`}</code></pre>
    <p>{`从其他 codebase 衍生时，需要在请求中指定基础的 codebase：`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl -X POST http://localhost:6060/api/v1/repo/hi --data '{"version":'1',"base":"/hello"}
`}</code></pre>
    <h3 {...{
      "id": "查看-codebase-详细信息"
    }}>{`查看 codebase 详细信息`}</h3>
    <p><inlineCode parentName="p">{`GET /api/v1/repo/[CODEBASE]`}</inlineCode></p>
    <p>{`查看 codebase 详细信息，包括版本、路径、文件列表等。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl -s http://localhost:6060/api/v1/repo/hello | jq .
{
  "version": "1", #版本
  "path": "/hello", #路径
  "main": "/main.js", #入口脚本
  "files": [ #文件列表
    "/main.js"
  ],
  "editFiles": [], #修改单未提交的文件
  "erasedFiles": [],
  "baseFiles": [],
  "derived": [],
  "instances": {}
}
`}</code></pre>
    <h3 {...{
      "id": "查看-codebase-文件内容"
    }}>{`查看 codebase 文件内容`}</h3>
    <p><inlineCode parentName="p">{`GET /api/v1/repo/[CODEBASE]/[FILE_NAME]`}</inlineCode>{`。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl -s http://localhost:6060/api/v1/repo/hello/main.js
pipy()
`}</code></pre>
    <h3 {...{
      "id": "更新-codebase-中的文件"
    }}>{`更新 codebase 中的文件`}</h3>
    <p><inlineCode parentName="p">{`POST /api/v1/repo/[CODEBASE]/[FILE_NAME]`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl -X POST http://localhost:6060/api/v1/repo/hello/main.js --data """pipy()
  .listen(8080)
  .serveHTTP(
    new Message('Hi!')
  )"""
  
$ curl -s http://localhost:6060/api/v1/repo/hello/main.js
pipy()
  .listen(8080)
  .serveHTTP(
    new Message('Hi!')
  )
#查看 codebasse，main.js 修改未提交  
$ curl -s http://localhost:6060/api/v1/repo/hello | jq .
{
  "version": "1",
  "path": "/hello",
  "main": "/main.js",
  "files": [
    "/main.js"
  ],
  "editFiles": [
    "/main.js"
  ],
  "erasedFiles": [],
  "baseFiles": [],
  "derived": [],
  "instances": {}
}
`}</code></pre>
    <h3 {...{
      "id": "发布-codebase-的变更"
    }}>{`发布 codebase 的变更`}</h3>
    <p><inlineCode parentName="p">{`POST /api/v1/repo/[CODEBASE]`}</inlineCode></p>
    <p>{`这个 API 与创建 codebase 的 API 类似，但请求内容增加了版本信息，且版本信息要与当前版本不同。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl -X POST http://localhost:6060/api/v1/repo/hello --data '{"version": '2'}'
`}</code></pre>
    <h3 {...{
      "id": "运行-codebase"
    }}>{`运行 codebase`}</h3>
    <p><inlineCode parentName="p">{`POST /api/v1/program`}</inlineCode></p>
    <p>{`使用 Pipy Repo 实例运行指定的 codebase，或者在 codebase 已运行的情况下重新加载。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl -X POST http://localhost:6060/api/v1/program --data '/hello'
`}</code></pre>
    <h3 {...{
      "id": "查看当前运行的-codebase"
    }}>{`查看当前运行的 codebase`}</h3>
    <p><inlineCode parentName="p">{`GET /api/v1/program`}</inlineCode></p>
    <p>{`查看当前 Pipy Repo 实例运行的 codebase。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl http://localhost:6060/api/v1/program
/hello
`}</code></pre>
    <h3 {...{
      "id": "停止当前运行的-codebase"
    }}>{`停止当前运行的 codebase`}</h3>
    <p><inlineCode parentName="p">{`DELETE /api/v1/program`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl -X DELETE http://localhost:6060/api/v1/program
`}</code></pre>
    <h3 {...{
      "id": "转储-repo"
    }}>{`转储 Repo`}</h3>
    <p><inlineCode parentName="p">{`GET /api/v1/dump-store`}</inlineCode></p>
    <p>{`在日志中打印所有 codebase 的信息：包括文件列表、内容等等。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl http://localhost:6060/api/v1/dump-store
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      